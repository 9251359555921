import { useValidation, yup } from '@/validation';

import { emailRule, codeRule } from './rules';

export const fieldValidation = ({ fieldName = '', rule }) => {
  const {
    initState, fieldsErrors, processError, clearState, hasDataBeenChanged, localizedErrors,
  } = useValidation();

  const fields = { [fieldName]: '' };
  const validationSchema = yup.object().shape({ [fieldName]: rule });

  const { requestFields } = initState(fields);

  return {
    fieldsErrors,
    validationSchema,
    requestFields,
    processError,
    clearState,
    hasDataBeenChanged,
    localizedErrors,
  };
};

export const emailValidation = () => {
  const { initState, fieldsErrors, processError } = useValidation();

  const fields = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: emailRule,
  });

  const { requestFields } = initState(fields);

  return {
    fieldsErrors,
    validationSchema,
    requestFields,
    processError,
  };
};

export const codeValidation = () => {
  const { initState, fieldsErrors, processError } = useValidation();

  const fields = {
    verifyCode: '',
  };

  const validationSchema = yup.object().shape({
    verifyCode: codeRule,
  });

  const { requestFields } = initState(fields);

  return {
    fieldsErrors,
    validationSchema,
    requestFields,
    processError,
  };
};
