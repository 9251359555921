import TronWeb from 'tronweb/dist/TronWeb';

import { i18n } from '@/plugins/localization';
import { yup } from '@/validation';
import { addThousandsSeparators, parseSeparatedNumber } from '@/utils/numberFormatter';

// const NAME_REGEXP = /^(?![-',.])(?!.*(--|\.\.|,,|''))[0-9a-zA-Z,.\-'\s]+(?<![-',])$/gm;
const IP_REGEXP = /^((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))$/gm;

export const STRING_WITHOUT_SPACES = /^(?!\s+).*?(?<!\s)$/s;
export const NO_DOUBLE_SPACES = /^(?:\s*\S+(?:\s\S+)*\s*)?$/gm;

const EMAIL = /^[\w\-_+.]+@[\p{L}\d]+\.[\p{L}]+$/u;
const LINK = /^(https?:\/\/)([^\s]+\.[^\s]+)/;
const ABC_AND_NUMBER = /^[a-zA-Z0-9.,\s]+$/;

const AMOUNT_RULE = (baseAmount, numberFormat, currency = 'USD') => yup
  .string()
  .transform((value) => (parseSeparatedNumber(value, numberFormat).toString()))
  .required('isRequired')
  .test({
    name: 'min',
    message: () => {
      const { t } = i18n.global;
      return t('errors.minimumAmountByMerchant', {
        currency,
        min: addThousandsSeparators({ val: baseAmount, separator: numberFormat }),
      });
    },
    params: { hasBeenTranslated: true },
    test: (value) => value !== null && value >= parseSeparatedNumber(baseAmount, numberFormat),
  })
  .max(999999999, 'notMaxThan');

export const codeRule = yup
  .string()
  .required('isRequired')
  .min(6, 'tokenLength')
  .max(6, 'tokenLength');

// used before in some cases (dnd). Check with this rule in case of bug
export const codeRuleAsNumber = yup.object().shape({
  verifyCode: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? 0 : value))
    .required('isRequired')
    .min(100000, 'tokenLength')
    .max(999999, 'tokenLength'),
});

export const stringWithoutSpaces = yup
  .string()
  .required('isRequired')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces');

export const emailRule = yup
  .string()
  .required('isRequired')
  .min(4, 'minLength')
  .matches(EMAIL, 'isNotEmail');
// .email('isNotEmail');

export const companyNameRule = yup
  .string()
  .required('isRequired')
  .min(6, 'minLength')
  .max(25, 'notMaxThan')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces');

export const nameRule = yup
  .string()
  .required('isRequired')
  .min(1, 'minLength')
  .max(25, 'notMaxThan')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces');

export const adminNameRule = yup
  .string()
  .required('isRequired')
  .min(5, 'minLength')
  .max(12, 'notMaxThan')
  .matches(ABC_AND_NUMBER, 'Only alphabets are allowed');

export const requiredRule = yup
  .string()
  .required('isRequired');

export const nameRuleAsAdmin = yup
  .string()
  .required('isRequired')
  .min(4, 'minLength')
  .max(25, 'notMaxThan')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces');

export const baseAmountRule = (baseAmount, numberFormat, currency = 'USD') => AMOUNT_RULE(baseAmount, numberFormat, currency);

export const minAmountRule = (baseAmount, numberFormat, currency = 'USD') => AMOUNT_RULE(baseAmount, numberFormat, currency)
  .test({
    name: 'min',
    message: () => {
      const { t } = i18n.global;
      return t('errors.minAmountCannotBeDecimal');
    },
    test: (value) => Number.isInteger(Number(value)),
  });

export const memoRule = ({ min, max } = {}) => yup
  .string()
  .required('isRequired')
  .min(min || 1, 'notLessThan')
  .max(max || 120, 'notMoreThan')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces');

export const booleanRule = yup
  .boolean()
  .required('isRequired')
  .oneOf([true], 'isRequired');

export const enumRule = yup
  .string()
  .required('isRequired');

export const coinKeyRule = (coin) => yup
  .string()
  .required('isRequired')
  .test({
    name: 'key',
    message: () => 'INVALID_INPUT',
    test: (value) => {
      switch (coin) {
        case 'BTC':
          return value.indexOf('ypub') === 0 && value.length === 111;
        case 'LTC':
          return value.indexOf('Mtub') === 0 && value.length === 111;
        case 'DASH':
          return value.indexOf('xpub') === 0 && value.length === 111;
        case 'ETH_ETH':
          return value.indexOf('0x') === 0 && value.length === 42;
        case 'XMR':
          return value.length === 64;
        case 'BSC_FDUSD':
          return value.indexOf('0x') === 0 && value.length === 42;
        case 'TRON_USDT':
          return TronWeb.isAddress(value);
        default:
          return false;
      }
    },
  });

export const bip49Rule = yup
  .string()
  .required('isRequired')
  .min(1, 'minLength')
  .max(111, 'notMaxThan');

export const ipRule = yup
  .string()
  .required('isRequired')
  .min(7, 'minLength')
  .max(39, 'notMaxThan')
  .matches(IP_REGEXP, 'matches_ip');

export const docUrlRule = (max = 120) => yup
  .string()
  .required('isRequired')
  .min(1, 'notLessThan')
  .max(max, 'notMoreThan')
  .test({
    name: 'key',
    message: () => 'INVALID_INPUT',
    test: (value) => value.startsWith(`https://${process.env.VUE_APP_DOCS_URL}`),
  });

export const uidAsSuperadmin = yup
  .string()
  .required('isRequired')
  .min(3, 'superUidLength')
  .max(7, 'superUidLength')
  .matches(/^[1-9]/, 'noZeroAsFirstDigit');

export const uidAsAdmin = yup
  .string()
  .required('isRequired')
  .min(7, 'uidLength')
  .max(7, 'uidLength')
  .matches(/^[1-9]/, 'noZeroAsFirstDigit');

export const urlRule = yup
  .string()
  .matches(LINK, 'isUrl')
  .matches(NO_DOUBLE_SPACES, 'extra_spaces')
  .max(500, 'notMaxThan');
