<template>
  <form class="w-100" autocomplete="off" @submit="onClick">
    <slot
      :errors="localizedErrors"
      :localized-errors="localizedErrors"
      :action="onClick"
      :successes="successesList"
    />
    <div class="actions" @click="onClick">
      <slot name="action" />
      <button type="submit" class="visually-hidden">
        SUBMIT
      </button>
    </div>
  </form>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';

import { coloredLog } from '@/utils/coloredLog';
import { useNotifications } from '@/composables/useNotifications';
import { useValidation } from '@/validation';

export default defineComponent({
  props: {
    action: {
      type: [Function, Boolean],
      default: false,
    },
    requestFields: {
      type: Object,
      default: () => {},
    },
    validationSchema: {
      type: Object,
      default: () => {},
    },
    responseErrors: {
      type: Object,
      default: () => {},
    },
    isBubbleNotificationVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success', 'error', 'validate', 'input'],
  setup(props, { emit }) {
    const {
      fieldsErrors, frontendErrors, doFrontendValidation, localizedErrors,
    } = useValidation();

    const emitErrors = (errors) => {
      fieldsErrors.value = errors;
      emit('error', errors);
    };

    const successesList = ref({});

    const findValidFields = () => {
      Object.keys(props.requestFields).forEach((key) => {
        if (!fieldsErrors.value[key]) {
          successesList.value[key] = true;
        }
      });
    };

    const onClick = async (params) => {
      if (params instanceof Event) {
        // params is event in current case
        params.preventDefault();
      }
      const frontendValidationHadBeenPassed = await doFrontendValidation(props.requestFields, props.validationSchema);
      findValidFields();

      if (frontendValidationHadBeenPassed) {
        coloredLog('FRONTEND VALIDATION HAS BEEN PASSED');

        if (params instanceof Event) {
          emit('validate');
        } else {
          emit('validate', params);
        }

        if (props.action) {
          const { isSuccess, data, errorObject } = await props.action(props.requestFields);
          if (isSuccess) {
            emit('success', data);
          } else {
            emitErrors(errorObject.fields);
          }
        }
      } else {
        if (props.isBubbleNotificationVisible) {
          const { addNotification } = useNotifications();
          addNotification({
            text: 'Validation error',
            config: { color: 'error' },
          });
        }
        emitErrors(fieldsErrors.value);
      }
    };

    const resetFrontendValidation = (fieldShouldBeReseted) => {
      try {
        delete fieldsErrors.value[fieldShouldBeReseted];
        delete frontendErrors.value[fieldShouldBeReseted];
        delete successesList.value[fieldShouldBeReseted];

        // emitErrors(frontendErrors.value);
      } catch (error) {
        console.error(error);
      }
    };

    const fieldsValues = computed(() => Object.entries(props.requestFields).map((el) => el));
    const resetFieldErrorWhenInput = (newVal, oldVal) => {
      emit('input');
      try {
        const foundField = oldVal.filter((value) => {
          const [oldValKey, oldValValue] = value;
          return oldValValue !== props.requestFields[oldValKey];
        });

        if (foundField.length) {
          resetFrontendValidation(foundField[0][0]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    watch(fieldsValues, resetFieldErrorWhenInput);

    const incomingErrors = computed(() => props.responseErrors && Object.entries(props.responseErrors).map((el) => el));
    watch(incomingErrors, () => {
      emitErrors(props.responseErrors);
    });

    return {
      onClick,

      fieldsErrors,
      successesList,

      localizedErrors,
    };
  },
});
</script>

<style scoped lang="scss">
.actions {
  width: 100%;
}
</style>
