import * as yup from 'yup';
import {
  computed, reactive, ref, watch,
} from 'vue';

import { getLocalizedErrorMessage } from '@/composables/useFetch/helpers';

import ActionForm from './ActionForm.vue';

export const useValidation = () => {
  const fieldsErrors = ref({});

  const fields = {};
  const requestFields = reactive({});

  const initState = (defaultFields) => {
    Object.assign(fields, defaultFields);
    Object.assign(requestFields, fields);

    return {
      requestFields,
    };
  };

  const clearState = () => {
    initState(fields);
    fieldsErrors.value = {};
  };

  const frontendErrors = ref({});
  const doFrontendValidation = async (inputFields, validationSchema) => {
    const frontendValidationHadBeenPassed = ref(false);

    try {
      await validationSchema.validate(inputFields, { abortEarly: false });
      frontendValidationHadBeenPassed.value = true;
    } catch (e) {
      if (!e.inner) {
        console.error('Check, may be rule should be a function!');
      }
      frontendErrors.value = {};
      e.inner.forEach((error) => {
        frontendErrors.value[error.path] = frontendErrors.value[error.path] ? frontendErrors.value[error.path] : [];

        const variables = error.params[error.type] ? {
          [error.type]: error.params[error.type],
        } : {};

        frontendErrors.value[error.path].push({
          message: error.errors[0],
          variables,
          hasBeenTranslated: Boolean(error.params?.hasBeenTranslated),
        });
      });

      fieldsErrors.value = frontendErrors.value;
    }

    return frontendValidationHadBeenPassed.value;
  };

  const localizedErrors = computed(() => {
    try {
      const result = {};
      Object.entries(fieldsErrors.value).forEach((error) => {
        const [field, value] = error;
        const firstError = value[0];

        if (firstError.hasBeenTranslated) {
          result[field] = { message: firstError.message };
          return;
        }

        const message = getLocalizedErrorMessage(firstError.message, firstError.variables);
        result[field] = { message };
      });

      return result;
    } catch (error) {
      return fieldsErrors.value;
    }
  });

  const process4xxError = (error, message = 'Error', fieldName = 'verifyCode') => {
    if ([400, 401].includes(error?.data?.code)) {
      fieldsErrors.value = {
        [fieldName]: [{ message }],
      };
    }
  };

  const processError = (errorObject, message = '', fieldName = 'verifyCode') => {
    fieldsErrors.value = errorObject.fields;
    process4xxError(errorObject.error, message, fieldName);
  };

  const hasDataBeenChanged = ref(false);

  setTimeout(() => {
    watch(requestFields, () => {
      hasDataBeenChanged.value = true;
    });
  }, 0);

  return {
    fieldsErrors,
    frontendErrors,
    initState,
    requestFields,
    clearState,
    doFrontendValidation,
    localizedErrors,

    processError,
    hasDataBeenChanged,
  };
};

export { ActionForm, yup };
